import React from 'react'
import Helmet from 'react-helmet'
import ConditionalLayout from '../components/ConditionalLayout'
import ServiceContainer from '../components/ServiceContainer'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'

import { LargeP, FlexList, FlexItem } from '../elements'
import RequestQuote from '../components/RequestQuote'

const DedicatedRegionalTransportPage = ({ data }) => {
  const postNode = {
    title: `Dedicated & Regional Transport - ${config.siteTitle}`,
  }

  return (
    <ConditionalLayout>
      <Helmet>
        <title>{`DedicatedRegionalTransport - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="contact" customTitle />

      <ServiceContainer>
        <PageTitle>Dedicated & Regional</PageTitle>
        <LargeP>
          D&D understands the importance of committed fleets and the service it
          takes to manage them.
        </LargeP>
        <p>
          With over three decades of experience we have built an extraordinary
          reputation shippers can trust. By managing several small fleets, we
          guarantee the load and the delivery by carriers exclusive to D&D. We
          also ensure that these carriers meet the requirements necessary for
          any load, abiding by the same standards that our company asset trucks
          follow. We also understand the bigger picture and can execute
          strategies to eliminate empty miles, reduce carbon emissions, and
          maximize profit.
        </p>

        <RequestQuote />
        <h4>Dedicated & Regional Benefits</h4>
        <FlexList>
          <FlexItem>Dependable & Consistent Service</FlexItem>
          <FlexItem>SmartWay Transport Provider</FlexItem>
          <FlexItem>Dedicated Account Manager</FlexItem>
          <FlexItem>Flexible Capacity</FlexItem>
          <FlexItem>Asset and Non-Asset Based</FlexItem>
          <FlexItem>On Call 24/7 Service</FlexItem>
          <FlexItem>Online Load Tracking</FlexItem>
          <FlexItem>Trusted Carrier Partners</FlexItem>
          <FlexItem>Modern & Fuel Efficient Equipment</FlexItem>
        </FlexList>
      </ServiceContainer>
    </ConditionalLayout>
  )
}

export default DedicatedRegionalTransportPage
